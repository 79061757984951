import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';
import { isFuture, parseJSON } from 'date-fns';
import NextLink from 'next/link';

import ContractBadge from 'components/ContractBadge';
import Pulse from 'components/Pulse';
import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import CollectionLogo from 'components/base/CollectionLogo';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Link from 'components/base/Link';
import Overlay from 'components/base/Overlay';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import ModeratedBanner from 'components/cards/shared/ModeratedBanner';
import MintPrice from 'components/collections/MintPrice';
import UserTag from 'components/users/UserTag';

import SplitsIcon from 'assets/icons/split-icon.svg';
import { ApiDropFragment } from 'gql/api/api-fragments.generated';
import {
  ApiCollectionSaleType,
  ApiEditionStatus,
} from 'gql/api/types-api.generated';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import {
  buildCollectionSymbol,
  getCollectionUrlSlug,
  getMintPrice,
  isSharedContract,
} from 'utils/collections';
import { formatDateTime } from 'utils/dates/dates';
import { getDropStartTime } from 'utils/drops';
import { formatNumber } from 'utils/formatters';
import { isNumberType } from 'utils/helpers';
import { optimizeAsset } from 'utils/imgix';
import { isFlaggedForModeration } from 'utils/moderation';
import { getPath } from 'utils/router';
import { createCanvasBackground } from 'utils/styles';

import { UserLight } from 'types/Account';
import { CollectionCardFragment, ContractType } from 'types/Collection';
import { Drop, DropStartTimes, Dynamic } from 'types/Drop';
import { DropSale } from 'types/DropSale';
import { ModerationStatus } from 'types/Moderation';
import { LinkHref } from 'types/router';
import { Maybe } from 'types/utils';

type RootProps = ComponentProps<typeof Root>;

export type CollectionCardBaseProps = {
  backgroundImageUrl?: Maybe<string> | undefined;
  creator: UserLight | null;
  hasSplits?: boolean;
  href: LinkHref;
  imageUrl?: Maybe<string> | undefined;
  moderationStatus: ModerationStatus;
  name: string;
  symbol: string | null;
  size?: RootProps['size'];
  disableHoverCard?: boolean;
  nftCount: number;

  // @deprecated use sale instead
  generalAvailabilityStartTime?: Drop['generalAvailabilityStartTime'];
};

type CollectionCardDynamicProps =
  | {
      type: ContractType;
      variant: 'BASIC';
    }
  | (DropStartTimes & {
      nftCount: Drop['nftCount'];
      sale: DropSale | null;
      saleType: ApiCollectionSaleType | null;
      type: 'DROP';
      variant: 'ENHANCED';

      /** @deprecated use sale instead */
      clearingPrice: Drop['clearingPrice'];
      /** @deprecated use sale instead */
      mintPrice: Drop['mintPrice'];
      /** @deprecated use sale instead */
      status: Drop['status'];
    })
  | {
      mintPrice: number;
      nftCount: number;
      status: ApiEditionStatus;
      type: 'TIMED_EDITION';
      variant: 'ENHANCED';
    };

type CollectionCardProps = CollectionCardBaseProps & CollectionCardDynamicProps;

const BASE_MIN_HEIGHT = 490;
const MINI_MIN_HEIGHT = 335;
const MINI_MIN_WIDTH = 340;

function CollectionCardBase(props: CollectionCardProps) {
  const {
    backgroundImageUrl,
    creator,
    disableHoverCard = false,
    hasSplits,
    href,
    imageUrl,
    moderationStatus,
    name,
    nftCount,
    size,
    symbol,
    type,
    variant,
  } = props;

  const isModerated = isFlaggedForModeration(moderationStatus);
  const backgroundStyles = backgroundImageUrl ? {} : createCanvasBackground({});

  const getFooter = () => {
    if (variant === 'ENHANCED') {
      if (type === 'DROP') {
        const {
          clearingPrice,
          mintPrice: dropMintPrice,
          sale,
          saleType,
        } = props;

        const maxTokenId = sale ? sale.maxTokenId : null;
        const startTime = getDropStartTime(props);

        const mintPrice = getMintPrice({
          saleType,
          mintPrice: dropMintPrice,
          clearingPrice,
        });

        // Created, but not scheduled
        if (props.status === 'UNSCHEDULED') {
          return (
            <FooterRow>
              <FooterCol>
                <FooterValue>Minting soon</FooterValue>
              </FooterCol>
            </FooterRow>
          );
        }

        // Scheduled drop that hasn't started yet — note that when a DROP is
        // open for early access, it is still considered "SCHEDULED" by the API
        if (props.status === 'SCHEDULED' && isFuture(parseJSON(startTime))) {
          return (
            <FooterRow>
              <FooterColScheduled startTime={getDropStartTime(props)} />
              <FooterColPrice mintPrice={mintPrice} />
            </FooterRow>
          );
        }

        // Drop that is available to buy
        if (isNumberType(maxTokenId) && nftCount < maxTokenId) {
          return (
            <FooterRow>
              <FooterColMinted nftCount={nftCount} maxTokenId={maxTokenId} />
              <FooterColPrice mintPrice={mintPrice} />
            </FooterRow>
          );
        }
      }

      if (type === 'TIMED_EDITION') {
        const { mintPrice } = props;

        // Scheduled
        if (
          props.status === 'SCHEDULED' &&
          props.generalAvailabilityStartTime
        ) {
          return (
            <FooterRow>
              <FooterColScheduled
                startTime={props.generalAvailabilityStartTime}
              />
              <FooterColPrice mintPrice={mintPrice} />
            </FooterRow>
          );
        }

        // Live
        if (props.status === 'OPEN') {
          return (
            <FooterRow>
              <FooterColMinted nftCount={nftCount} maxTokenId={null} />
              <FooterColPrice mintPrice={mintPrice} />
            </FooterRow>
          );
        }
      }
    }

    if (nftCount > 0) {
      return (
        <FooterRow>
          <FooterColNfts nftCount={nftCount} />
        </FooterRow>
      );
    } else {
      return null;
    }
  };

  return (
    <Root size={size} style={backgroundStyles}>
      <Overlay css={{ zIndex: -1 }} />
      {isModerated && (
        <>
          <ModeratedBannerPlaceholder />
          <ModeratedBanner status={moderationStatus} />
        </>
      )}
      {backgroundImageUrl && (
        <ImageWrapper>
          <Image
            alt=""
            src={optimizeAsset(backgroundImageUrl, {
              'max-h': 490,
              'max-w': 370,
              fit: 'crop',
            })}
          />
        </ImageWrapper>
      )}
      <NextLink href={href} passHref prefetch={false}>
        <CollectionLink />
      </NextLink>
      <Header>
        {imageUrl ? (
          <CollectionLogo
            alt={name}
            imageUrl={imageUrl}
            size={7}
            variant="blur"
          />
        ) : (
          <CollectionLogo.Skeleton size={7} variant="blur" />
        )}
        <ContractBadge
          contractType={type}
          size={1}
          variant="blur"
          symbol={buildCollectionSymbol({
            name,
            symbol,
          })}
        />
      </Header>
      <Info>
        <InfoMeta>
          <Heading lineHeight={0} size={5}>
            {name}
          </Heading>
        </InfoMeta>
        {creator && type !== 'SHARED' && (
          <InfoCreator>
            <Box css={{ minWidth: 0 }}>
              <UserTag
                disableHoverCard={disableHoverCard}
                nameVariant="prefer-username"
                size={0}
                user={creator}
                variant="blur"
              />
            </Box>
            {hasSplits && (
              <SplitsIconWrapper>
                <SplitsIcon />
              </SplitsIconWrapper>
            )}
          </InfoCreator>
        )}
      </Info>
      <Box css={{ marginTop: 'auto' }}>{getFooter()}</Box>
    </Root>
  );
}

type CollectionCardSkeletonProps = {
  size?: RootProps['size'];
};

function CollectionCardSkeleton(props: CollectionCardSkeletonProps) {
  return (
    <Skeleton.Block
      css={{
        width: '100%',
        padding: '$5',
        borderRadius: '$2',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...(props.size === 'mini'
          ? { minHeight: MINI_MIN_HEIGHT, minWidth: MINI_MIN_WIDTH }
          : { minHeight: BASE_MIN_HEIGHT }),
      }}
      opaque
    >
      <Flex css={{ flexDirection: 'column', gap: '$6' }}>
        <Header>
          <Skeleton.Block
            as="span"
            css={{
              borderRadius: '$4',
              flexGrow: 0,
              width: 86,
              height: 86,
            }}
            opaque
          />
          <Skeleton.Button
            css={{ width: '90px', height: '34px' }}
            variant="fill"
            opaque
          />
        </Header>
        <Skeleton.Block
          css={{ width: '100%', maxWidth: 140, height: 34, marginBottom: '$2' }}
          opaque
        />
      </Flex>
      <FooterCol>
        <Skeleton.Block
          css={{ width: 50, height: 18, marginBottom: '$2' }}
          opaque
        />
        <Skeleton.Block css={{ width: 100, height: 26 }} opaque />
      </FooterCol>
    </Skeleton.Block>
  );
}

function FooterColNfts(props: { nftCount: number }) {
  return (
    <FooterCol>
      <FooterLabel>NFTs</FooterLabel>
      <FooterValue>{formatNumber(props.nftCount)}</FooterValue>
    </FooterCol>
  );
}

function FooterColScheduled(props: { startTime: string }) {
  return (
    <FooterCol>
      <FooterLabel>Scheduled</FooterLabel>
      <FooterValue>{formatDateTime(parseJSON(props.startTime))}</FooterValue>
    </FooterCol>
  );
}

function FooterColMinted(props: {
  nftCount: number;
  maxTokenId: Maybe<number>;
}) {
  const { nftCount, maxTokenId } = props;

  return (
    <FooterCol>
      <FooterLabel>Minted</FooterLabel>
      <FooterValue>
        <Flex css={{ alignItems: 'center', gap: '$1' }}>
          <Pulse color="$white100" />
          <span>
            <Text as="span">{formatNumber(nftCount)}</Text>
            {isNumberType(maxTokenId) && (
              <Text as="span" css={{ color: '$white80' }}>
                &#8201;&#47;&#8201;
                {formatNumber(maxTokenId)}
              </Text>
            )}
          </span>
        </Flex>
      </FooterValue>
    </FooterCol>
  );
}

function FooterColPrice(props: { mintPrice: Maybe<number | Dynamic> }) {
  const { mintPrice } = props;

  if (mintPrice === null) {
    return null;
  }

  return (
    <FooterCol>
      <FooterLabel>Price</FooterLabel>
      <FooterValue>
        <MintPrice mintPrice={mintPrice} />
      </FooterValue>
    </FooterCol>
  );
}

const Root = styled('div', {
  padding: '$5',
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',

  willChange: 'transform',
  transform: 'translate3d(0, 0, 0)',
  transition: 'transform $1 $ease, box-shadow $1 $ease',

  borderRadius: '$4',
  color: 'currentColor',
  boxShadow: '$regular0',
  background: '$black100',

  variants: {
    size: {
      mini: {
        minHeight: MINI_MIN_HEIGHT,
        minWidth: MINI_MIN_WIDTH,
      },
      surface: {
        minHeight: BASE_MIN_HEIGHT,
        '@hover': {
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '$regular1',
          },
          '&:active': {
            transform: 'translateY(0)',
            boxShadow: '$regular0',
          },
        },
      },
    },
  },

  defaultVariants: {
    size: 'surface',
  },
});

const ImageWrapper = styled('div', {
  top: 0,
  left: 0,
  zIndex: -2,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

const Image = styled('img', {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
});

const CollectionLink = styled(Link, {
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

const Header = styled('div', {
  gap: '$3',
  display: 'flex',
  position: 'relative',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [`${Badge}`]: {
    marginLeft: 'auto',
  },
});

const FooterRow = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  gap: '$5',
});

const FooterCol = styled('div', {});

const FooterLabel = styled(Text, {
  color: '$white80',
  marginBottom: '2px',
});
FooterLabel.defaultProps = {
  size: 1,
  weight: 'semibold',
};

const FooterValue = styled(Text, {
  color: '$white100',
});
FooterValue.defaultProps = {
  size: 3,
  weight: 'semibold',
};

const Info = styled('div', {
  gap: '$3',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$3',
});

const InfoMeta = styled('div', {
  gap: '$1',
  display: 'flex',
  flexDirection: 'column',

  [`${Text}`]: {
    color: '$white70',
  },

  [`${Heading}`]: {
    lineHeight: '$1',
    color: '$white100',
    wordBreak: 'break-word',
  },
});

const InfoCreator = styled('div', {
  gap: '$2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  zIndex: 1,
  marginTop: 'auto',
  position: 'relative',
});

const SplitsIconWrapper = styled('div', {
  width: '$icon1',
  height: '$icon1',
  color: '$white100',
  borderRadius: '$round',
  background: '$black100',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  left: '4px',
  bottom: '4px',

  svg: {
    height: 'auto',
    width: '55%',
    display: 'block',
  },
});

const ModeratedBannerPlaceholder = styled('div', {
  width: '100%',
  pointerEvents: 'none',
  height: '$formElement0',
});

type CollectionCardConnectedProps = {
  collection: CollectionCardFragment;
  creator: CollectionCardProps['creator'];
  size?: CollectionCardProps['size'];
  nftCount: number;
  hasSplits?: boolean;
  href?: LinkHref;
  disableHoverCard?: boolean;
};

function CollectionCardConnected(props: CollectionCardConnectedProps) {
  const { collection, creator, href, size, hasSplits } = props;

  const isShared = isSharedContract(collection.contractType);

  const urlSlug = getCollectionUrlSlug(collection);
  const collectionPath = getPath.collection.page(urlSlug);

  return (
    <CollectionCard
      type={collection.contractType}
      variant="BASIC"
      backgroundImageUrl={collection.coverImageUrl}
      creator={creator && !isShared ? creator : null}
      href={href || collectionPath}
      imageUrl={collection.collectionImageUrl}
      moderationStatus={collection.moderationStatus}
      nftCount={props.nftCount}
      name={collection.name}
      symbol={collection.symbol}
      size={size}
      hasSplits={hasSplits}
    />
  );
}

type CollectionCardApiProps = {
  collection: Pick<
    ApiDropFragment,
    | 'clearingPrice'
    | 'collectionImageUrl'
    | 'contractAddress'
    | 'coverImageUrl'
    | 'earlyAccessStartTime'
    | 'generalAvailabilityStartTime'
    | 'mintPrice'
    | 'moderationStatus'
    | 'name'
    | 'nftCount'
    | 'saleConfiguration'
    | 'saleType'
    | 'slug'
    | 'status'
    | 'symbol'
  >;
  creator: UserLight;
  hasSplits?: boolean;
  size?: CollectionCardProps['size'];
};

/**
 * Note: This is essentially coupled to the data fetching from the API specifically for Drops.
 * Ideally long-term we can remove this layer.
 */
function CollectionCardDrop(props: CollectionCardApiProps) {
  const { collection, creator, hasSplits, size } = props;

  const sale = mapDropSaleConfigurationToDropSale(collection.saleConfiguration);

  const urlSlug = getCollectionUrlSlug({
    contractAddress: collection.contractAddress,
    slug: collection.slug,
  });

  return (
    <CollectionCardBase
      backgroundImageUrl={collection.coverImageUrl}
      creator={creator}
      earlyAccessStartTime={collection.earlyAccessStartTime}
      generalAvailabilityStartTime={collection.generalAvailabilityStartTime}
      hasSplits={hasSplits}
      href={getPath.collection.page(urlSlug)}
      imageUrl={collection.collectionImageUrl}
      sale={sale}
      mintPrice={collection.mintPrice}
      moderationStatus={collection.moderationStatus}
      name={collection.name}
      nftCount={collection.nftCount}
      size={size}
      status={collection.status}
      symbol={collection.symbol}
      type="DROP"
      variant="ENHANCED"
      clearingPrice={collection.clearingPrice}
      saleType={collection.saleType}
    />
  );
}

const CollectionCard = Object.assign(CollectionCardBase, {
  Drop: CollectionCardDrop,
  Connected: CollectionCardConnected,
  Skeleton: CollectionCardSkeleton,
});

export default CollectionCard;
