import { CollectionByContractAddress } from 'gql/hasura/queries/collection-by-contract-address.generated';

type Collection = CollectionByContractAddress['collections'][number];

export const selectCollectionByContractAddress = (
  data: CollectionByContractAddress
): Collection | null => {
  if (data.collections[0]) {
    return data.collections[0];
  } else {
    return null;
  }
};
