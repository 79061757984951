import NextLink from 'next/link';
import { ReactNode } from 'react';

import Avatar, { AvatarProps } from 'components/base/Avatar';
import AvatarText, { AvatarTextProps } from 'components/base/AvatarText';
import Box from 'components/base/Box';
import ButtonWithAvatar, {
  ButtonWithAvatarProps,
} from 'components/base/ButtonWithAvatar';
import Link, { LinkProps } from 'components/base/Link';

import {
  getCollectionLabel,
  getCollectionUrlSlug,
  isSharedContract,
} from 'utils/collections';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';
import { CollectionCardFragment } from 'types/Collection';

import CollectionHoverCard from './CollectionHoverCard';

type CollectionTagBaseProps = {
  collection: CollectionCardFragment;
  creator: UserLight;
  disableHoverCard?: boolean;
  type?: 'avatar' | 'button' | 'avatar-text';
};

type CollectionTagTypeProps =
  | {
      type: 'avatar';
      size?: AvatarProps['size'];
    }
  | {
      type?: 'button';
      size?: ButtonWithAvatarProps['size'];
    }
  | {
      type: 'avatar-text';
      variant?: LinkProps['variant'];
      size?: AvatarTextProps['size'];
    };

type CollectionTagProps = CollectionTagBaseProps & CollectionTagTypeProps;

export default function CollectionTag(props: CollectionTagProps) {
  const { collection, creator, disableHoverCard } = props;

  if (!collection) {
    return null;
  }

  const collectionName = getCollectionLabel(collection);
  const mono = !collection.name;

  const sharedProps = {
    collection,
    creator,
    disableHoverCard,
  };

  if (props.type === 'avatar') {
    return (
      <CollectionTagWrapper {...sharedProps}>
        <Avatar
          alt={collectionName}
          imageUrl={collection.collectionImageUrl}
          shape="square"
          size={props.size}
        />
      </CollectionTagWrapper>
    );
  }

  if (props.type === 'avatar-text') {
    return (
      <CollectionTagWrapper variant={props.variant} {...sharedProps}>
        <AvatarText
          imageUrl={collection.collectionImageUrl}
          mono={mono}
          shape="square"
          size={props.size}
          text={collectionName}
        />
      </CollectionTagWrapper>
    );
  }

  return (
    <CollectionTagWrapper {...sharedProps}>
      <ButtonWithAvatar
        as="div"
        imageUrl={collection.collectionImageUrl}
        mono={mono}
        shape="square"
        size={props.size}
        text={collectionName}
        variant="raised"
      />
    </CollectionTagWrapper>
  );
}

CollectionTag.defaultProps = {
  size: 1,
  type: 'button',
  disableHoverCard: false,
};

// CollectionTagWrapper will decide wether to render the hover card or not
type CollectionTagWrapperProps = Exclude<CollectionTagBaseProps, 'type'> & {
  children: ReactNode;
  variant?: LinkProps['variant'];
};

function CollectionTagWrapper(props: CollectionTagWrapperProps) {
  const { collection, creator, disableHoverCard, children, variant } = props;

  const isSharedCollection = isSharedContract(collection?.contractType);
  const collectionSlug = getCollectionUrlSlug(collection);
  const collectionPath =
    isSharedCollection && creator.publicKey
      ? getPath.collection.byCreator(collectionSlug, creator.publicKey)
      : getPath.collection.page(collectionSlug);

  const content = (
    <NextLink href={collectionPath} passHref prefetch={false}>
      <Link variant={variant}>{children}</Link>
    </NextLink>
  );

  // If the hover card is disabled, we return link only
  if (disableHoverCard) {
    return content;
  }

  return (
    <Box css={{ maxWidth: '100%' }}>
      <CollectionHoverCard collection={collection} creator={creator}>
        {content}
      </CollectionHoverCard>
    </Box>
  );
}
