import Tippy, { TippyProps } from '@tippyjs/react';
import { ReactNode, useState } from 'react';
import 'tippy.js/animations/shift-away.css';

import Box from 'components/base/Box';
import CollectionCard from 'components/cards/collections/CollectionCard';

import { useCollectionByContractAddress } from 'gql/hasura/queries/collection-by-contract-address.generated';
import { selectCollectionByContractAddress } from 'hooks/queries/hasura/collections/use-collection-by-contract-address';
import usePopoverContainerRef from 'hooks/use-popover-container-ref';
import { DEFAULT_TIPPY_PROPS } from 'utils/popover';

import { UserLight } from 'types/Account';
import { CollectionCardFragment, CollectionFilter } from 'types/Collection';

type TippyHoverCardProps = {
  delay?: TippyProps['delay'];
  offset?: TippyProps['offset'];
  placement?: TippyProps['placement'];
};

type BaseCollectionHoverCardProps = TippyHoverCardProps & {
  children: ReactNode;
};

type CollectionHoverCardProps =
  | (BaseCollectionHoverCardProps & {
      collection: CollectionCardFragment;
      creator: UserLight;
      type?: 'normal';
    })
  | (BaseCollectionHoverCardProps & {
      filter: CollectionFilter;
      type: 'lazy';
    });

export default function CollectionHoverCard(props: CollectionHoverCardProps) {
  const { children, delay, offset, placement, type = 'normal' } = props;

  const [isTriggered, setIsTriggered] = useState(false);

  // TODO: update this to use a chain-aware API query
  const collectionQuery = useCollectionByContractAddress(
    {
      contractAddress:
        props.type === 'lazy'
          ? props.filter.contractAddress
          : props.collection.contractAddress,
    },
    {
      enabled: type === 'lazy' && isTriggered,
      select: selectCollectionByContractAddress,
    }
  );

  const getContent = () => {
    if (props.type === 'lazy') {
      if (collectionQuery.isLoading)
        <Box css={{ background: '$white100' }}>
          <CollectionCard.Skeleton size="mini" />
        </Box>;

      if (collectionQuery.isError) return null;

      if (collectionQuery.data) {
        const collection = collectionQuery.data;

        return (
          <CollectionCard.Connected
            collection={collection}
            creator={collection.creator}
            disableHoverCard
            nftCount={0}
            size="mini"
          />
        );
      }

      // Should never happen
      return null;
    }

    return (
      <CollectionCard.Connected
        collection={props.collection}
        creator={props.creator}
        nftCount={0}
        size="mini"
        disableHoverCard
      />
    );
  };

  return (
    <CollectionHoverCardTippy
      content={getContent()}
      delay={delay}
      placement={placement}
      offset={offset}
      onTrigger={() => setIsTriggered(true)}
    >
      {children}
    </CollectionHoverCardTippy>
  );
}

type CollectionHoverCardTippyProps = TippyHoverCardProps & {
  content: ReactNode;
  children: ReactNode;
  onTrigger(): void;
};

function CollectionHoverCardTippy(props: CollectionHoverCardTippyProps) {
  const { children, content, delay, offset, placement, onTrigger } = props;
  const popoverContainerRef = usePopoverContainerRef();
  return (
    <Tippy
      {...DEFAULT_TIPPY_PROPS}
      content={content}
      delay={delay || DEFAULT_TIPPY_PROPS.delay}
      placement={placement || DEFAULT_TIPPY_PROPS.placement}
      offset={offset || DEFAULT_TIPPY_PROPS.offset}
      animation="shift-away"
      touch={false}
      appendTo={popoverContainerRef.current}
      onTrigger={onTrigger}
      interactive
    >
      <Box css={{ cursor: 'pointer', minWidth: 0 }}>{children}</Box>
    </Tippy>
  );
}
